<template>
  <b-card title="السعر">
    <b-tabs>
      <b-tab :active="currentRouteName=='classical-course-price'" @click="$router.push('/classical-course-price')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/classical-course-price"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>سعر الدورة الكلاسيكية</span>
          </a>
        <!-- <a  href="/classical-course-price">Classical Course Prices</a> -->
        </template>

     
      </b-tab>
      <b-tab :active="currentRouteName=='online-course-price'"
        @click="$router.push('/online-course-price')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/online-course-price"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>سعر الدورة الاونلاين</span>
          </a>
        <!-- <a  href="/online-course-price">Online Course Prices</a> -->
        </template>
        
       <!-- <OnlineCoursePrice @add-price="getData"></OnlineCoursePrice> -->
      </b-tab>
      <b-tab :active="currentRouteName=='all-prices'"
         @click="$router.push('/all-prices')" >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/all-prices"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
        <!-- <a  href="/all-prices"> Prices</a> -->
        <span>السعر</span>
        </a>
        </template>
        

      <!-- <ViewPrice ref="allData"></ViewPrice> -->
      </b-tab>
      <b-tab :active="currentRouteName=='add-specific-price'" 
        @click="$router.push('/add-specific-price')" >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/add-specific-price"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
        <!-- <a  href="/add-specific-price"> Specific Price List
</a> -->
<span>قائمة أسعار محددة</span>
</a>
        </template>

      </b-tab>
      <b-tab :active="currentRouteName=='code-generator'"
        @click="$router.push('/code-generator')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/code-generator"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span>    توليد الكود </span>
          </a>
        <!-- <a  href="/code-generator">Code Generator</a> -->
        </template>
      </b-tab>
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <ClassicalCoursePrices v-if="route.name === 'classical-course-price' "  />
      <AddSpecificPrice v-if="route.name === 'index-specific-price' || route.name === 'add-specific-price'  "  />
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>

    
  </b-card>
</template>

<script>
import AddSpecificPrice from './specific-price/AddSpecificPrice.vue';
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import ClassicalCoursePrices from './ClassicalCoursePrices.vue';
import OnlineCoursePrice from './OnlineCoursePrice.vue';
import ViewPrice from './ViewPrice.vue';
import index from './specific-price/index.vue'
import CodeGenerator from './CodeGenerator.vue';
import { ref } from "@vue/composition-api";


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    ClassicalCoursePrices,
    OnlineCoursePrice,
    AddSpecificPrice,
    ViewPrice,
    index,
    CodeGenerator
},
setup() {
    const allData=ref()
  
    const getData=()=>{
    
      allData.value.refetchData()
           

    }
   return{
    getData,
    allData,
   

   }
   
   }
   ,
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}}
</script>
